@import "../../variables.scss";

html {
  scroll-behavior: smooth;
}

/*This is to fix the bug in wsbc.css*/
a.btn-blue {
  color: $btn-text-color;
}

a.btn-blue:hover {
  color: $btn-text-color;
}
a.btn-grey {
  color: $btn-text-color;
}
a.btn-grey:hover {
  color: $btn-text-color;
}
a.btn-outline-blue {
  color: $btn-text-outline-color;
}

button.btn-outline-blue:disabled {
  color: $btn-text-color;
}

a.btn-outline-blue:hover {
  color: $btn-text-hover-color;
}

.btn.btn-md {
  margin-bottom: 10px !important; /*to fix bug in common css*/
  margin-right: 10px;
}

.invalid-message {
  padding: 0px 0px 5px 0px;
  color: $control-color;
  display: block;
  margin-top: 5px;
}

.invalid-message:empty {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
}

.top-margin {
  margin-top: 10px;
}

.modal-custom {
  max-width: 600px;
}

.modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
}

@media only screen and (max-width: 768px) {
  .mb-width {
    width: 100%;
  }
}
